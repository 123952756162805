<template>
    <div class="o365-body-center-viewport" :class="class" :ref="viewportRefFunction" @scroll.passive="handleScroll" :data-o365-container="navigationContainer"
        v-edge-scroll="{ enabled: dataGridControl?.gridFocusControl?.isDragging, scrollThreshold: 64}">

        <ErrorRenderer v-if="capturedError" />
        <template v-else>
    
        <slot name="overlay"></slot>

        <div class="o365-body-left-pinned-cols" v-if="dataColumns.hasPinnedLeftColumns"
            :style="[{'min-width':dataColumns.leftPinnedWidth+'px'},{'max-width':dataColumns.leftPinnedWidth+'px'},{'width':dataColumns.leftPinnedWidth+'px'},{'height': setHeight ? (dataHeight ?? (dataLength * dataGridControl.props.rowHeight)) + 'px' : null}]">

                <template v-for="(item, itemIndex) in normalRows" :key="itemIndex">
                    <slot name="left" :row="item" :rowIndex="item.index">
                    </slot>
                </template>

                <template v-for="col in leftColumnsWithOverlays">
                    <div class="position-absolute h-100 pe-none" :style="{'left': col.left+'px', 'width': col.width+'px'}">
                        <component :is="col.overlaySlot" :data="data"></component>
                    </div>
                </template>
                
        </div>

        <div class="o365-body-center-cols" :style="{'min-height': setHeight ? (dataHeight ?? (dataLength * dataGridControl.props.rowHeight)) + 'px' : null}">
            <div class="o365-body-center-cols-viewport" style="height: calc(100% + 17px);" @scroll.passive="handleWidthScroll">
                <div class="o365-body-center-cols-container" :style="[{'width':dataColumns.centerWidth+ dataColumns.unusedWidth + 'px'}]">

                        <template v-for="(item, itemIndex) in normalRows" :key="itemIndex">
                            <slot name="center" :row="item" :rowIndex="item.index">
                            </slot>
                        </template>

                </div>

                <template v-for="col in centerColumnsWithOverlays">
                    <div class="position-absolute h-100 pe-none" :style="{'left': col.left+'px', 'width': col.width+'px'}">
                        <component :is="col.overlaySlot" :data="data"></component>
                    </div>
                </template>

            </div>
        </div>

        <div class="o365-body-right-pinned-cols" v-if="dataColumns.hasPinnedRightColumns"
        :style="[{'min-width':dataColumns.rightPinnedWidth+'px'},{'max-width':dataColumns.rightPinnedWidth+'px'},{'width':dataColumns.rightPinnedWidth+'px'},{'height': setHeight ? (dataHeight ?? (dataLength * dataGridControl.props.rowHeight)) + 'px' : null}]">
            <template v-for="(item, itemIndex) in normalRows" :key="itemIndex">
                <slot name="right" :row="item" :rowIndex="item.index">
                </slot>
            </template>

            <template v-for="col in rightColumnsWithOverlays">
                <div class="position-absolute h-100 pe-none" :style="{'left': col.left+'px', 'width': col.width+'px'}">
                    <component :is="col.overlaySlot" :data="data"></component>
                </div>
            </template>
        </div>

        <div v-if="dataGridControl.props.isFullWidthRow != null" class="o365-full-width-rows-container"
            :style="{
                'height': setHeight ? (dataHeight ?? (dataLength * dataGridControl.props.rowHeight)) + 'px' : null
            }">
            <template v-for="(item, itemIndex) in fullWidthRows" :key="itemIndex">
                <slot name="fullWidth" :row="item" :rowIndex="item.index"></slot>
            </template>
        </div>
        <slot name="misc"></slot>

        </template>
    </div>

    
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useErrorCapture, vEdgeScroll } from 'o365-vue-utils';
import useVirtualColumns from './composables.ColumnVirtualization.ts';

const props = defineProps({
    class: null,
    data: Object,
    dataGridControl: Object,
    viewportRefFunction: Function,
    dataLength: Number,
    setHeight: {
        type: Boolean,
        default: true
    },
    dataHeight: Number,
    handleScroll: Function,
    navigationContainer: {
        type: String,
        default: 'G'
    }
});

const dataColumns = computed(() => {
    return props.dataGridControl?.dataColumns;
})

const dataLength = computed(() => {
    return props.dataLength ?? props.data?.length;
})

const normalRows = computed(() => {
    if (props.dataGridControl.props.isFullWidthRow == null) {
        return props.data;
    }
    return props.data.filter(x => !props.dataGridControl.props.isFullWidthRow(x.item));
});

const fullWidthRows = computed(() => {
    if (props.dataGridControl.props.isFullWidthRow == null) {
        return [];
    }
    return props.data.filter(x => props.dataGridControl.props.isFullWidthRow(x.item));
});

const leftColumnsWithOverlays = computed(() => {
    return props.dataGridControl?.dataColumns.leftColumns.filter(col => col.overlaySlot);
});
const centerColumnsWithOverlays = computed(() => {
    return props.dataGridControl?.dataColumns.centerColumns.filter(col => col.overlaySlot);
});
const rightColumnsWithOverlays = computed(() => {
    return props.dataGridControl?.dataColumns.rightColumns.filter(col => col.overlaySlot);
});

const [capturedError, ErrorRenderer] = useErrorCapture({
    consoleMessagee: `Error encountered when trying to render grid list: ${props.dataGridControl?.id}`,
    errorRenderFunctionOptions: {
        type: 'card',
        uiMessage: 'An unhandled error has occurred when rendering the contents of this grid'
    }
});

const virtualColumns = props.dataGridControl.props.enableVirtualColumns
    ? useVirtualColumns()
    : {};

function handleWidthScroll(pEvent) {
    if (virtualColumns?.handleScroll) {
        virtualColumns.handleScroll(pEvent);
    }
}

const headerRows = [];

onMounted(() => {
    props.dataGridControl.header?.headerContainer?.querySelectorAll('.o365-header-viewport')?.forEach(headerRow => {
        headerRows.push(headerRow);
    })
});

</script>

<script>
export default {
    name: 'ODataGridDataList'
}
</script>
